import { IoCheckmarkSharp } from 'react-icons/io5'
import './ServiceTile.css'
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { cartAtom, employeeSelectionDialogAtom, storeDetailAtom } from '../App/App_state';
import { Item } from '../App/App_Store_interface';

// store item detail ListTile
export default function ServiceTile(props: {
  service: Item,
}) {
  // cart getter and setter  
  const [cart, addToCart] = useAtom(cartAtom)

  // is item already in cart
  let isInsideCart = cart.map(e => e.id).includes(props.service.id)

  const setEmployeeSelectionItem = useSetAtom(employeeSelectionDialogAtom)

  const storeDetail = useAtomValue(storeDetailAtom)


  return (
    <button aria-label='Add To Cart' onClick={() => {
      props.service.cartQty = 1
      if (isInsideCart) {
        addToCart(cart.filter((service) => service.id !== props.service.id))
      } else {
        if (storeDetail?.isMultipleEmployeeEnabled ?? false) {
          setEmployeeSelectionItem(props.service)
        } else {
          addToCart([...cart, props.service]);
        }
      }
    }}>
      <div className='ServiceTile'>

        {isInsideCart
          ? <div className='checkBox checkBox_check canvasDark'>
            <IoCheckmarkSharp color='white' size={22} />
          </div>
          : <div className='checkBox'></div>}

        <div>
          <h3>{props.service.name}</h3>
          <small>{props.service.durationInMinutes === undefined ? props.service.uom : formatDuration(props.service.durationInMinutes)}</small>
        </div>

        <h3>{`₹ ${formatCost(props.service.cost.toString())}`}</h3>


      </div>
    </button>
  )
}

// format number eg, 1,22,333
export function formatCost(cost: string) {
  return cost.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

// format minutes to string eg, 15 mins, 2 hrs etc
export function formatDuration(durationInMinutes: number): string {
  const weeks = Math.floor(durationInMinutes / (7 * 24 * 60));
  const days = Math.floor((durationInMinutes % (7 * 24 * 60)) / (24 * 60));
  const hours = Math.floor((durationInMinutes % (24 * 60)) / 60);
  const minutes = durationInMinutes % 60;

  return [
    weeks > 0 ? `${weeks} week${weeks > 1 ? 's' : ''}` : '',
    days > 0 ? `${days} day${days > 1 ? 's' : ''}` : '',
    hours > 0 ? `${hours} hr${hours > 1 ? 's' : ''}` : '',
    minutes > 0 ? `${minutes} min${minutes > 1 ? 's' : ''}` : ''
  ].filter(Boolean).join(' ');
}

