import { ChangeEventHandler } from 'react'
import './TextField.css'
import { storeDetailAtom } from '../App/App_state'
import { useAtomValue } from 'jotai'

export default function TextField(props: {
  label: string
  value: string
  onChange: ChangeEventHandler<any>
}) {
  const storeDetail = useAtomValue(storeDetailAtom)!
  return (
    <div className='input-group'>
      {props.label === 'Address' || props.label === 'Remark'
        ? <textarea aria-label={props.label} value={props.value} onChange={props.onChange} rows={4} maxLength={storeDetail.remarkMaxLength ?? 142} required ></textarea>
        : <input type='text' aria-label={props.label} value={props.value} onChange={props.onChange} required />
      }
      <span className='highlight'></span>
      <span className='bar'></span>
      <label> {props.label} </label>
    </div>
  )
}
